<template>
  <Dialog v-model:visible="dialogActive" class="dialog-file-preview" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}" :closable="false" :draggable="false" modal @hide="closeDialog">
    <template #header>
      <h3 class="mb-2">Email de Simulation</h3>
    </template>
    <Editor v-model="simulationMailTemplate" editorStyle="flex"/>
    <template #footer>
      <div class="flex justify-content-end">
        <Button label="Annuler" class="p-button-outlined p-button-rounded" @click="closeDialog" />
        <Button label="Valider et envoyer" class="p-button-rounded" @click="sendWithCurrentTemplate" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "SimulationMailDialog",
  emits: ['closeDialog', 'sendWithCurrentTemplate'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      dialogActive: false,
      simulationMailTemplate: ''
    }
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings
    })
  },
  watch: {
    async isActive(value) {
      if (value) {
        await this.init()
      }
      this.dialogActive = value
    }
  },
  async created () {
    await this.init()
  },
  methods: {
    async init () {
        this.simulationMailTemplate = this.settings.Commercial.simulationEmailTemplate ?? ''
    },
    async sendWithCurrentTemplate () {
      this.$confirm.require({
        header: 'Envoi Simulation',
        message: 'Êtes-vous sûr d\'envoyer la simulation avec cette email',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$emit('sendWithCurrentTemplate', { simulationMailTemplate: this.simulationMailTemplate })
          this.$confirm.close()
        },
        reject: () => {
        }
      })
    },
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>

</style>
