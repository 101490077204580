<template>
  <div id="simulation-tab-view" class="flex flex-container non-scrollable surface-section col-11 mr-0 p-0 flex-grow-1" :class="contentClass">
    <h1 v-if="showHeader && !idSimulation && simulation && simulation.independent" class="m-0 pt-5 pl-5">
      {{ `${simulation.independent.last_name.toUpperCase()} ${simulation.independent.first_name}` }}
    </h1>
    <div class="surface-section pt-2 flex-container non-scrollable">
      <TabView ref="tabview1" v-model:active-index="tab">
        <TabPanel v-if="simulationGenerated" :header="'Simulation ' + simulationGenerated.coefPaid + ' ' + (simulationGenerated.typeMission === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE ? 'heures' : (simulationGenerated.typeMission === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE ? 'jours' : ' de coefficient')) "/>
        <TabPanel v-if="simulationFake" :header="'Simulation ' + simulationFake.coefInvoiced + ' ' + (simulationFake.typeMission === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE ? 'heures' : (simulationFake.typeMission === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE ? 'jours' : ' de coefficient')) "/>
        <TabPanel v-if="bulletinGenerated" :header="'Bulletin de salaire ' + simulationGenerated.coefPaid + ' ' + (simulationGenerated.typeMission === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE ? 'heures' : (simulationGenerated.typeMission === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE ? 'jours' : ' de coefficient')) "/>
      </TabView>
      <!-- Change to outer disposition to automatically scale with the size of the screen wih flex and flex-grow on iframe -->
      <iframe v-if="tab === 0" class="flex-grow-1" width="100%" :src='pdf1'/>
      <iframe v-if="tab === 1 && simulationFake" class="flex-grow-1" width="100%" :src='pdf2'/>
      <iframe v-if="tab === (simulationFake ? 2 : 1)" class="flex-grow-1" width="100%" :src='pdf3'/>

      <div v-if="simulation.status !== constants.SIMULATION_STATUS.VALIDATED && !readOnly" class="grid pt-2 pb-2 pr-0 mr-0">
        <div class="lg:col-6 md:col-3" />
        <div class="pr-0 lg:col-6 md:col-9">
          <Button class="m-1 p-button-rounded p-button-outlined" @click="savePdf" >
            Télécharger {{ (tab !== 2 && simulationFake) || (tab !== 1 && !simulationFake) ? 'la simulation' : 'le bulletin'}}
          </Button>
          <Button class="m-1 p-button-rounded" @click="validateSimulation" >
            Valider la simulation
          </Button>
          <div class="spacer"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PdfGenerator from "@/utils/PdfGenerator";
import {mapState} from "vuex";

export default {
  name: 'GeneratedSimulations',
  emits: ['validateSimulation'],
  props: {
    idSimulation: {
      type: Number,
      default: null
    },
    simulation: {
      type: Object,
      default: () => { return {} }
    },
    contentClass: {
      type: String,
      required: false
    },
    simulations: {
      type: Object,
      default: () => { return {} }
    },
    settings: {
      type: Object,
      default: () => { return {} }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tab: 0,

      pdf1: null,
      pdf2: null,
      pdf3: null,

      showSimulation: false,

      pdfGenerator1: null,
      pdfGenerator2: null,
      pdfGenerator3: null,

      simulationGenerated: undefined,
      bulletinGenerated: undefined,
      simulationFake: undefined
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
  },
  async created () {
    console.log('created')
    await this.generate()
  },
  methods: {
    savePdf () {
      if (this.tab === 0) {
        this.pdfGenerator1?.save('Simulation.pdf')
      } else if (this.tab === 1 && this.simulationFake) {
        this.pdfGenerator2?.save('Simulation.pdf')
      } else if (this.tab === 2 || !this.simulationFake) {
        this.pdfGenerator3?.save('Bulletin De Salaire Prévisionnel.pdf')
      }
    },
    async generate () {
      try {
        if (!this.simulation?.id) {
          return
        }
        this.$store.dispatch('simulation/generateSimulation', { id: this.simulation.id }).then((simulation) => {
          this.simulationGenerated = simulation.simulation
          if (simulation.simulation.coefInvoiced !== simulation.simulation.coefPaid) {
            this.$store.dispatch('simulation/generateSimulation', { id: this.simulation.id, invoiced: true }).then((invoicedSimulation) => {
              this.simulationFake = invoicedSimulation.simulation
              this.simulationFake.coefPaid = this.simulationFake.coefInvoiced
              this.pdfGenerator2 = new PdfGenerator({ ...this.simulationFake, settings: this.settings, constants: this.constants }, 'simulationV2')
              this.pdfGenerator2.getPdf((dataUrl) => {
                this.pdf2 = dataUrl
                this.showSimulation = true
              })
            })
          }
          this.pdfGenerator1 = new PdfGenerator({ ...this.simulationGenerated, settings: this.settings, constants: this.constants }, 'simulationV2')
          this.pdfGenerator1.getPdf((dataUrl) => {
            this.pdf1 = dataUrl
            this.showSimulation = true
          })

          this.bulletinGenerated = simulation.bulletin
          this.pdfGenerator3 = new PdfGenerator({
            bulletin: this.bulletinGenerated,
            name: this.simulation.independent.last_name.toUpperCase() + ' ' + this.simulation.independent.first_name.toUpperCase(),
            date: new Date(this.simulation.date_updated ?? this.simulation.date_created),
            settings: {
              companySettings: this.settings.Entreprise,
              settings: this.settings,
            },
            constants: this.constants
          }, 'bulletin')
          this.pdfGenerator3.getPdf((dataUrl) => {
            this.pdf3 = dataUrl
            this.showSimulation = true
          })
        })
      } catch (e) {
        console.log(e)
        throw Error(e.message)
      }
    },
    validateSimulation () {
      this.$emit('validateSimulation', {})
    }
  }
}
</script>

<style>
#simulation-tab-view .p-tabview .p-tabview-panels {
  /*text-align: center !important;*/
  padding-left: 0 !important;
}
</style>
