<template>
  <div class="flex-container non-scrollable">
    <div class="flex align-items-center justify-content-between pl-0 pt-0 pr-0 pb-3 xhl:px-5" v-if="!loading">
      <NameHeader :independent="independent"></NameHeader>
      <div v-if="hasAccess($route.name, true)" class="flex" style="gap: 1rem">
        <Button :label="$t('view', {target: $t('simulation').toLowerCase()})" class="p-button-rounded" size="small"
                :disabled="!canGenerateSimulation" @click="generateSimulation" />
        <Button v-if="simulation.status === constants.SIMULATION_STATUS.DRAFT" :label="$t('save')" size="small"
                class="p-button-rounded" :disabled="!canSaveSimulation" @click="save" />
        <Button v-if="simulation.status === constants.SIMULATION_STATUS.DRAFT || simulation.status === constants.SIMULATION_STATUS.TO_VALIDATE" :label="$t('send')" size="small"
                class="p-button-rounded" :disabled="!canSendSimulation" @click="sendSimulationConfirmation" />
      </div>
    </div>
    <div class="scrollable">
      <fiche-simulation
          v-if="!loading"
          ref="ficheSimu"
          @canGenerate="(value) => canGenerateSimulation = value"
          @canSave="(value) => canSaveSimulation = value"
          @canSend="(value) => canSendSimulation = value"
      />
      <div v-else class="overflow-hidden  flex">
        <ProgressSpinner></ProgressSpinner>
      </div>
      <div class="spacer"/>
    </div>
    <Dialog
      v-if="showSimulation"
      class="pdfIframe"
      content-class="flex p-0 flex-grow-1"
      :breakpoints="{'1400px': '90vw'}"
      :style="{ width: '65vw', height: '75vh' }"
      :visible="showSimulation"
      :modal="true"
      :dismissable-mask="true"
      :draggable="false"
      @update:visible="showSimulation = false"
    >
      <div class="flex-container non-scrollable mx-auto flex flex-grow-1">
        <generated-simulations
          class="align-content-center"
          content-class="col-12"
          :simulation="simulation"
          :settings="settings"
          read-only
        />
      </div>
    </Dialog>
    <SimulationMailDialog :is-active="isActiveMailDialog" @close-dialog="closeSimulationMailDialog" @send-with-current-template="sendSimulation"/>
  </div>
</template>

<script>
import NameHeader from "@/components/NameHeader";
import FicheSimulation from "@/components/FicheSimulation";
import {mapState} from "vuex";
import Alert from "@/utils/Alert";
import GeneratedSimulations from "@/components/GeneratedSimulations.vue";
import {hasAccess, disablePComponentsOfCurrentPage} from "@/utils/Misc";
import SimulationMailDialog from "@/components/SimulationMailDialog.vue";
export default {
  name: "FicheProspect",
  components: {SimulationMailDialog, GeneratedSimulations, FicheSimulation, NameHeader},
  data () {
    return {
      hasAccess: hasAccess,
      tab: 1900,
      loading: true,
      independent: null,
      ficheSimulation: 0,
      showSimulation: false,
      pdf: '',

      isActiveMailDialog: false,

      canGenerateSimulation: false,
      canSaveSimulation: false,
      canSendSimulation: false,
    }
  },
  computed: {
    ...mapState({
      simulation: state => state.simulation.simulation,
      simulations: state => state.simulation.simulations,
      settings: state => state.misc.settings,
      constants: state => state.constants
    })
  },
  watch: {
    loading () {
      if (!this.loading) {
        this.$nextTick(() => {
          if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
        })
      }
    }
  },
  async created () {
    this.independent = await this.$store.dispatch('independent/getOneIndependent', this.$route.params.idProspect)

    if (!this.independent) {
      await this.$router.push({name: 'Prospects'})
    }

    await this.$store.dispatch('simulation/getSimulationsIndependent', this.$route.params.idProspect)
    await this.$store.dispatch('simulation/setSimulationBySimulationId', this.$route.params.idSimulation)
    this.loading = false
  },
  methods: {
    async save () {
      if (!this.canSaveSimulation) return
      if (!this.simulation.commercial) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(1)'})
      } else {

        if (!(this.constants.REGEX_VALIDATORS.EMAIL.test(this.simulation.independent.email))) {
          Alert.errorMessage(this, 'invalidEmail')
          return
        }

        await this.$store.dispatch('simulation/saveSimulation', this.simulation).then(() => {
          Alert.successMessage(this, 'saveSimulation')
          this.$refs.ficheSimu.cloneSimulation()
        })
      }
    },
    async generateSimulation () {
      if (!this.canGenerateSimulation) return

      await this.save()

      this.formatDates()
      this.showSimulation = true
    },
    formatDates () {
      if (this.simulation.jsonClient && this.simulation.jsonClient.startingDate) {
        let date = new Date(this.simulation.jsonClient.startingDate)
        this.simulation.jsonClient.startingDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
      }
      if (this.simulation.jsonClient && this.simulation.jsonClient.endingDate) {
        let date = new Date(this.simulation.jsonClient.endingDate)
        this.simulation.jsonClient.endingDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
      }
    },
    closeSimulationMailDialog () {
      this.isActiveMailDialog = false
    },
    async sendSimulationConfirmation () {
      if (!this.canSendSimulation) return
      this.isActiveMailDialog = true
    },
    async sendSimulation (payload) {
      this.$store.state.misc.loading = true
      this.formatDates()
      try {
        await this.$store.dispatch('simulation/sendSimulation', { simulation: this.simulation, emailTemplate: payload.simulationMailTemplate, role: this.constants.ROLES.PROSPECT})
        this.closeSimulationMailDialog()
        Alert.successMessage(this, 'sendSimulation')
        this.$store.state.misc.loading = false
      } catch (error) {
        this.closeSimulationMailDialog()
        Alert.errorMessage(this, error.response.data.key ?? 'sendSimulation', {}, error.response.data.key ? 10000 : 3000)
        this.$store.state.misc.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
